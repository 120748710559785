<template>
  <v-container>
    <invite-service-providers
      :show="dialog"
      :service-providers="serviceProviders"
      @invited="dialog = false"
      @cancel="dialog = false"
    />
    <cards-logo
      :companies="serviceProviders"
      type="provider"
      @buttonClick="dialog = true"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { CardsLogo } from '@kickbox/common-admin';
import userService from '@/services/userService';
import InviteServiceProviders from './InviteServiceProviders';

export default {
  components: {
    CardsLogo,
    InviteServiceProviders
  },
  data() {
    return {
      search: '',
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'serviceProviders'
    ])
  },
  beforeRouteEnter(to, from, next) {
    next(async () => {
      if (!from.name) {
        await userService.getServiceProviders();
      }
    });
  }
};
</script>
