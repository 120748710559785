<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500px"
    class="invite-sp-container"
  >
    <v-card class="py-5 px-3">
      <v-card-title>Service Provider Invitation</v-card-title>
      <v-card-text>
        <v-form>
          <v-textarea
            v-model="emails"
            autofocus
            validate-on-blur
            label="Emails"
            no-resize
            placeholder="Add or paste emails comma separated"
            hint="Press Enter to add"
            @keypress.enter.prevent="validateEmails(emails)"
          />
        </v-form>
        <label
          v-if="emailsToAdd.length"
          class="mt-2"
        >
          Total: {{ emailsToAdd.length }}
        </label>
        <v-card class="mt-3">
          <v-list
            v-if="emailsToAdd.length"
            max-height="200px"
            class="list-container"
          >
            <v-list-item
              v-for="emailAddress in emailsToAdd"
              :key="emailAddress"
              class="tile-container"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ emailAddress }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon @click="deleteEmail(emailAddress)">
                    delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <app-button
          secondary
          cancel
          @click="$emit('cancel')"
        >
          Close
        </app-button>
        <app-button
          class="ml-4"
          :disabled="!emailsToAdd.length"
          @click="invite"
        >
          Send
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidateMixin } from '@kickbox/common-admin';
import serviceProviderService from '@/services/serviceProviderService';

export default {
  name: 'InviteServiceProviders',
  mixins: [ValidateMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    serviceProviders: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      emails: '',
      emailsToAdd: []
    };
  },
  methods: {
    validateEmails(spEmails) {
      const emails = spEmails.split(',').map((e) => e.trim());
      let validEmails = 0;
      emails.forEach((email) => {
        const validEmail = this.checkEmail(email);
        const emailExists = this.emailsToAdd.some((e) => e.includes(email));
        const isServiceProvider = this.serviceProviders.some((sp) => sp.email === email);
        if (validEmail && !emailExists && !isServiceProvider) {
          validEmails += 1;
          this.emailsToAdd.unshift(email.toLowerCase());
        }
      });
      this.emails = '';
      if (validEmails !== emails.length) {
        this.$store.dispatch('showSnackBar', { text: 'At least one of the emails is either not valid or was already added previously.' });
      }
    },
    deleteEmail(email) {
      const index = this.emailsToAdd.indexOf(email);
      this.emailsToAdd.splice(index, 1);
    },
    invite() {
      const customText = this.emailsToAdd.length === 1 ? 'invitation was' : 'invitations were';
      this.$store.dispatch('showSnackBar', { text: `The ${customText} sent` });
      serviceProviderService.inviteServiceProvider(this.emailsToAdd);
      this.emailsToAdd = [];
      this.$emit('invited');
    }
  }
};
</script>

<style scoped>
.tile-container:not(:last-child) {
  border-bottom: 1px solid #d0cbcb !important;
}
.list-container {
  overflow-y: scroll;
}
</style>
